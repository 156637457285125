// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('jquery')
require("jquery-ui");
require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("chartkick")
require("chart.js")

import 'tether'
import 'bootstrap'

require('utility')
require('views')

// function requireAll(r) { r.keys().forEach(r); }

// requireAll(require.context('../utility/', true, /\.js$/));
// requireAll(require.context('../views/', true, /\.js$/));

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).on("focus", "[data-behaviour~='datepicker']", function (e) {
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate())
  $(this).daterangepicker({
    singleDatePicker: true, minDate: tomorrow, locale: {
      format: 'YYYY-MM-DD'
    }
  })
});

$(document).on("focus", "[data-behaviour~='timepicker']", function (e) {
  $(this).daterangepicker({
    singleDatePicker: true,
    timePicker: true,
    timePicker24Hour: true,
    timePickerIncrement: 1,
    timePickerSeconds: false,
    autoApply: false,
    autoUpdateInput: true,
    minTime: "06:00:00",
    locale: {
      format: 'HH:mm'
    },
    onClose: function () {
      $("#campaign_duration").focus();
    }
  }).on('show.daterangepicker', function (ev, picker) {
    picker.container.find(".calendar-table").hide();
  })
  $('#text_message_start_time').on('apply.daterangepicker', function (ev, picker) { $(this).blur(); });
  $('#text_message_end_time').on('apply.daterangepicker', function (ev, picker) { $(this).blur(); });
  $('#black_out_text_message_campaign_start_time').on('apply.daterangepicker', function (ev, picker) { $(this).blur(); });
  $('#black_out_text_message_campaign_end_time').on('apply.daterangepicker', function (ev, picker) { $(this).blur(); });
});
$(document).ready(function() {
  function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
      return uri + separator + key + "=" + value;
    }
  }
  $('.show-hide-psw').on('click',function(){
    if($('#session_password').val() !=""){
      if($('#session_password').attr('type') == "password"){
        $('#session_password').attr('type', 'text');
        $('.show-hide-psw i').removeClass( "fa-eye-slash" );
        $('.show-hide-psw i').addClass( "fa-eye" );
      }else if($('#session_password').attr('type') == "text"){
       $('#session_password').attr('type', 'password');
       $('.show-hide-psw i').addClass( "fa-eye-slash" );
       $('.show-hide-psw i').removeClass( "fa-eye" );
     }
   }
 })
  $('.customer_checkbox').change(function(){
    var url = window.location.href
    if($(this).is(':checked')){
      if($(this).attr('id') == 'customer_kyc'){
        url =  updateQueryStringParameter(url,'customer_kyc',true)
      }else if($(this).attr('id') == 'customer_profile'){
        url =  updateQueryStringParameter(url,'customer_profile',true)
      }
    }else{
      var key = $(this).attr('id')
      url = url.replace(new RegExp(key + "=\\w+"),"").replace("?&","?")
      .replace("&&","&")
    }
    url = url.replace("&&","&")
    window.history.pushState({path:url},'',url);
    if(window.location.search.length == 0){
      url = url.replace("?","")
    }
    window.location.reload()
  })
  $('#user_team_ids').multiselect();

})

